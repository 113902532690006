<app-avatar *ngIf="isAvatarReady" [avatarInput]="avatar" [readonly]="false" [backgroundColor]="backgroundColor" [hasUserInfoPopover]="hasUserInfoPopover" (avatarPopoverOutput)="handleAvatarPopoverOutput($event)">
    <div *ngIf="hasIndicator(indicators, Indicators.Availability) && person"
        class="availability-indicator"
        [class.available]="available"
        [ngStyle]="availabilityIndicatorStyle"
    ></div>
    <div *ngIf="hasIndicator(indicators, Indicators.Teams) && person?.teamsOnlyUser"
        class="teams-indicator"
        [ngStyle]="teamsIndicatorStyle">
        <app-icon class="teams-icon" [ngStyle]="teamsIconStyle" [svgFile]="svgIcon.Teams" [height]="svgHeight || '0'" [width]="svgWidth || '0'"></app-icon>    
    </div>
    <div *ngIf="hasIndicator(indicators, Indicators.VideoCall)"
        class="call-indicator flexRow jcC aiC"
        [ngStyle]="callIndicatorStyle">
       
        <app-icon class="call-icon"
                    [icon]="{'svgFile': 'video-call', 'color': 'white', 'height': svgHeight, 'width': svgWidth}"
                    style="display: flex; align-items: center;"
                ></app-icon>
    </div>
    <div *ngIf="hasIndicator(indicators, Indicators.VoiceCall)"
        class="call-indicator flexRow jcC aiC"
        [ngStyle]="callIndicatorStyle">
        <app-icon [class]="'fas fa-phone-flip'"></app-icon>
    </div>
</app-avatar>

<ng-content></ng-content>
