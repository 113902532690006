import { getLocalPubSubPort, getLocalSiblingUrl } from './local-urls';

export class FrontendEnvironment {
    public readonly domain: string;
    public readonly subDomainSuffix: string | undefined;
    public readonly env: string;

    /*
        hostname should be the fully qualified hostname and port (if applicable), e.g.
            radio.weavixdev.com
            console-wn-1234.weavixdev.com
            controlcenter-canary.weavix.com
            localhost:4201
    */
    constructor(hostname: string) {
        // example: 'weavixdev.com' grabbed from 'controlcenter.weavixdev.com'
        this.domain = /\.(weavix.*?\.com)/.exec(hostname)?.[1] || 'localhost';

        // example: '-wn-1234' grabbed from 'controlcenter-wn-1234.weavixdev.com'
        this.subDomainSuffix = /[^-]*(-?.*?)\.weavix.*?\.com/.exec(hostname)?.[1];

        // example: 'dev' grabbed from 'weavixdev.com'
        this.env = this.domain === 'localhost' ? 'local' : /weavix(.*?)\.com/.exec(this.domain)?.[1] || 'prod';
    }

    public getSiblingUrl(subDomain: string): string {
        if (this.domain === 'localhost') {
            return getLocalSiblingUrl(subDomain);
        } else {
            return `https://${subDomain}${this.subDomainSuffix}.${this.domain}`;
        }
    }

    public getPubSubPort(): number {
        if (this.domain === 'localhost') {
            return getLocalPubSubPort();
        } else {
            return 443;
        }
    }

    public isProduction(): boolean {
        return this.domain === 'weavix.com' || this.domain === 'weavixdemo.com';
    }

    public getReleaseStage(): string {
        const feature = this.subDomainSuffix?.slice(1);
        if (this.env === 'prod' && feature === 'canary') {
            return 'canary';
        } else return feature || this.env;
    }
}
